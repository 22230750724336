import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './CardsDisplay.css'; // Assurez-vous que le chemin est correct

const CardsDisplay = () => {
  const [cards, setCards] = useState([]);
  const [loading, setLoading] = useState(true);
  const [flippedCard, setFlippedCard] = useState(null); // État pour la carte retournée
  const [selectedImage, setSelectedImage] = useState(null); // État pour l'image sélectionnée
  // Charger le son de retournement de carte
  const cardFlipSound = new Audio(`${process.env.PUBLIC_URL}/assets/card_sound.mp3`);

  useEffect(() => {
    const fetchCards = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/Card/GetCard`);
        setCards(response.data);
      } catch (error) {
        console.error("Erreur lors de la récupération des cartes :", error);
      } finally {
        setLoading(false);
      }
    };

    fetchCards();
  }, []);


  const handleCardClick = (cardId, imgPath) => {
    // Jouer le son de retournement de carte
    cardFlipSound.play().catch(error => console.error("Erreur lors de la lecture du son :", error));
    
    setFlippedCard(flippedCard === cardId ? null : cardId);
    setSelectedImage(imgPath);
  };

  if (loading) {
    return <div>Chargement...</div>;
  }

  if (cards.length === 0) {
    return <div className="empty-cards-message">Aucune carte à afficher</div>;
  }

  return (
    <div className="cards-container">
      {cards.map((card) => {
        const imgPath = `${process.env.PUBLIC_URL}/assets/${card.name.replaceAll(' ', '_')}.png`;
        return (
          <div 
            key={card.listCardId} 
            className={`card ${flippedCard === card.listCardId ? 'flipped' : ''}`} 
            onClick={() => handleCardClick(card.listCardId, imgPath)}
          >
            <div className="card-face card-face-front">
              <img src={imgPath} alt={card.name} />
            </div>
            <div className="card-face card-face-back">
              <img src={imgPath} alt={card.name} />
            </div>
          </div>
        );
      })}
      {selectedImage && (
        <div 
          className="modal" 
          onClick={() => setSelectedImage(null)}
        >
          <div 
            className="modal-content" 
            onClick={(e) => e.stopPropagation()}
          >
            <img 
              src={selectedImage} 
              alt="Selected" 
              className="modal-image"
              style={{ width: '50%', height: '70%', margin: 'auto'}} 
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default CardsDisplay;
