import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TablePagination,
  Box, Typography, IconButton, Divider
} from '@mui/material';
import { useTheme } from "@mui/material/styles";
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { purple } from "@mui/material/colors";
import { tokens } from '../theme';
import { useAuth } from '../context/AuthContext';

function formatDate(dateString) {
  const date = new Date(dateString);
  return date.toLocaleString('fr-FR', {
    year: 'numeric', month: 'long', day: 'numeric',
    hour: '2-digit', minute: '2-digit', second: '2-digit',
    hour12: false
  });
}

const LogBook = () => {
  const [logs, setLogs] = useState([]);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const { authData } = useAuth();

  useEffect(() => {
    const fetchLogs = async () => {
      try {
        const token = authData.token;
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/User/GetUserLogbook`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        setLogs(response.data);
      } catch (error) {
        console.error('Failed to fetch logs:', error);
      }
    };
    fetchLogs();
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  return (
    <Box sx={{ padding: 3, bgcolor: 'transparent' }}>
      <Typography variant="h3" gutterBottom sx={{ color: purple[500], textAlign: 'center', marginBottom: '20px' }}>
        Journal de bord
      </Typography>
      <Divider variant="middle" sx={{ my: 2, mx: 'auto', width: '80%', marginBottom: '20px' }} />
      <Paper sx={{ width: '100%', overflow: 'hidden', bgcolor: colors.primary[400] }}>
        <TableContainer sx={{ maxHeight: 440 }}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell sx={{ color: purple[400] }}>Date et Heure</TableCell>
                <TableCell sx={{ color: purple[400] }}>Message</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
            {logs.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((log) => (
  <TableRow key={log.logbookId} hover> {/* Assurez-vous que log.logbookId est unique */}
    <TableCell >{formatDate(log.logAt)}</TableCell>
    <TableCell >{log.logMessage}</TableCell>
  </TableRow>
))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5]}
          component="div"
          count={logs.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          labelDisplayedRows={({ from, to, count }) => `${to} sur ${count}`}
          sx={{
            '.MuiTablePagination-toolbar': {
              justifyContent: 'center',
              color: theme.palette.getContrastText(colors.primary[400]),
            },
            '.MuiTablePagination-selectLabel': {
              display: 'none',
            },
            '.MuiTablePagination-select': {
              display: 'none',
            },
            '.MuiTablePagination-actions': {
              color: theme.palette.getContrastText(colors.primary[400]),
            },
            bgcolor: theme.palette.primary[400],
          }}
          ActionsComponent={(subprops) => (
            <div style={{ flexShrink: 0 }}>
              <IconButton
                onClick={(event) => subprops.onPageChange(event, subprops.page - 1)}
                disabled={subprops.page === 0}
                aria-label="previous page"
              >
                <NavigateBeforeIcon />
              </IconButton>
              <IconButton
                onClick={(event) => subprops.onPageChange(event, subprops.page + 1)}
                disabled={subprops.page >= Math.ceil(subprops.count / subprops.rowsPerPage) - 1}
                aria-label="next page"
              >
                <NavigateNextIcon />
              </IconButton>
            </div>
          )}
        />
      </Paper>
    </Box>
  );
};

export default LogBook;
