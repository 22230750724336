import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Doughnut } from 'react-chartjs-2';
import { useAuth } from '../context/AuthContext';
import { Chart as ChartJS, registerables } from 'chart.js';
import { purple } from '@mui/material/colors';
import { useTheme } from "@mui/material/styles";

ChartJS.register(...registerables);

const GraphuserTimeTotalTimeG = () => {
    const theme = useTheme();
    const [timeData, setTimeData] = useState({ userTime: 0, totalTime: 0 });
    const { authData } = useAuth();

    function convertSecondsToTime(seconds) {
        let hours = Math.floor(seconds / 3600);
        let minutes = Math.floor((seconds - (hours * 3600)) / 60);
        let secondsLeft = seconds - (hours * 3600) - (minutes * 60);

        return `${hours > 0 ? `${hours}heure(s) ` : ''}${minutes > 0 ? `${minutes}minute(s) ` : ''}${secondsLeft}seconde(s)`;
    }

    useEffect(() => {
        const fetchData = async () => {
            try {
                const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/Tracker/GetTotalAndUserTime`, {
                    headers: { 'Authorization': `Bearer ${authData.token}` }
                });
                setTimeData({ 
                    userTime: data.userTimeSpent, // Supposons que c'est déjà en secondes
                    totalTime: data.totalTimeSpent  // Supposons que c'est déjà en secondes
                });
            } catch (error) {
                console.error('Erreur lors de la récupération des temps', error);
            }
        };

        fetchData();
    }, [authData.token]);

    const userTimePercentage = (timeData.userTime / timeData.totalTime) * 100;
    const remainingTimePercentage = 100 - userTimePercentage;

    const data = {
        labels: ['Votre temps en % d\'utilisation du site', 'Le temps cumulé des utilisateurs en %'],
        datasets: [
            {
                data: [userTimePercentage, remainingTimePercentage],
                backgroundColor: [purple[400], '#36A2EB'],
                hoverBackgroundColor: [purple[600], '#36A2EB']
            }
        ]
    };

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                position: 'bottom',
                labels: {
                    color: theme.palette.text.primary,
                    font: {
                        size: 14
                    }
                }
            },
            tooltip: {
                callbacks: {
                    label: (tooltipItem) => `${tooltipItem.label}: ${tooltipItem.parsed.toFixed(2)}%`,
                }
            }
        }
    };

    let timeDisplay = convertSecondsToTime(timeData.userTime); // Les données sont déjà en secondes
    const styles = {
        container: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center',
            color: theme.palette.text.primary,
        },
        graphContainer: {
            width: '100%',
            maxWidth: '1000px', // Taille du graphique ajustée
            display: 'flex',
            justifyContent: 'center',
            margin: 'auto',
        },
        description: {
            marginBottom: '20px',
            
        },
        userTime: {
            color: purple[400], // Utilisez la couleur secondaire ou celle de votre choix
            fontSize: '1rem', // Ajustez la taille de police selon vos besoins
            textAlign: 'center', // Assurez-vous que ce texte est centré
            marginTop: '10px', // Ajoutez une marge en haut si nécessaire
        },
    };

    return (
        <div style={styles.container}>
            
        <p style={styles.description}>Ce graphique montre votre temps passé sur le site par rapport au temps total des utilisateurs.</p>
        <div style={styles.graphContainer}>
            <Doughnut data={data} options={options} />
        </div>
        <p style={styles.userTime}>
            Vous avez cumulé un total de {timeDisplay} sur le site.
        </p>

    </div>
    );
}

export default GraphuserTimeTotalTimeG;
