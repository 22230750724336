import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useAuth } from '../context/AuthContext';
import './CardsDisplay.css';
import dayjs from 'dayjs';
import InfoIcon from '@mui/icons-material/Info';
import CloseIcon from '@mui/icons-material/Close';
import { tokens } from "../theme";
import { useTheme } from "@mui/material/styles";

const UserCardsDisplay = () => {
  const [cards, setCards] = useState([]);
  const [loading, setLoading] = useState(true);
  const { authData } = useAuth();
  const [modalContent, setModalContent] = useState(null);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  useEffect(() => {
    const fetchUserCards = async () => {
      if (authData && authData.token) {
        try {
          const config = {
            headers: { Authorization: `Bearer ${authData.token}` },
          };
          const response = await axios.get(`${process.env.REACT_APP_API_URL}/Card/GetUserCards`, config);
          setCards(response.data);
          if (response.data.length === 0) {
            console.log('Aucune carte trouvée');
          }
        } catch (error) {
          console.error("Erreur lors de la récupération des cartes de l'utilisateur :", error);
        } finally {
          setLoading(false);
        }
      } else {
        // Gestion si authData ou token n'est pas disponible
        setLoading(false);
      }
    };

    fetchUserCards();
  }, [authData]); // Dépendances de useEffect

  const handleCardClick = (card) => {
    const sound = new Audio(`${process.env.PUBLIC_URL}/assets/card_sound.mp3`);
    sound.play().catch(error => console.error("Erreur lors de la lecture du son :", error));

    const now = dayjs();
    const expirationDate = dayjs(card.dateObtained).add(1, 'month');
    const remainingTime = expirationDate.diff(now, 'day');

    setModalContent({
      imgPath: `${process.env.PUBLIC_URL}/assets/${card.name.replaceAll(' ', '_')}.png`,
      name: card.name,
      description: card.description,
      remainingUses: card.remainingUses,
      remainingTime: remainingTime > 0 ? `${remainingTime} jours` : "Expirée",
    });
  };

  if (loading) {
    return <div className="loading-container">Chargement...</div>;
  }

  if (cards.length === 0) {
    return <div className="empty-cards-message">Vous n'avez pas de carte ou vous n'avez pas lié votre compte. Allez vérifier dans l'onglet Mon compte</div>;
  }

  return (
    <div className="cards-container">
      {cards.map((card) => (
        <div key={card.listCardId} className="card">
          <div className="card-face card-face-front">
            <img src={`../assets/${card.name.replaceAll(' ', '_')}.png`} alt={card.name} />
            <button className="info-button" onClick={() => handleCardClick(card)}>
              <InfoIcon />
            </button>
          </div>
          <div className="card-face card-face-back">
            <img src={`../assets/${card.name.replaceAll(' ', '_')}.png`} alt={card.name} />
          </div>
        </div>
      ))}
      {modalContent && (
        <div className="modal" onClick={() => setModalContent(null)}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <div className="modal-image-container">
              <img src={modalContent.imgPath} alt={modalContent.name} className="modal-image" />
            </div>
            <div className="modal-details" style={{ background: colors.primary[400] }}>
              <CloseIcon className="close-modal" onClick={(e) => {
                e.stopPropagation();
                setModalContent(null);
              }} />
              <h2>{modalContent.name}</h2> {/* Nom de la carte */}
              <p className="description">{modalContent.description}</p> {/* Description de la carte */}
              <div className="details-container">
                <div className="detail">
                  <span className="detail-label">Utilisations restantes :</span>
                  <span className="detail-value">{modalContent.remainingUses}</span>
                </div>
                <div className="detail">
                  <span className="detail-label">Temps restant :</span>
                  <span className="detail-value">{modalContent.remainingTime}</span>
                </div>
                {/* Vous pouvez ajouter plus de détails ici en suivant le même modèle */}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default UserCardsDisplay;
