import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom'; // Assurez-vous d'installer react-router-dom si ce n'est pas déjà fait
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import axios from 'axios';
import { useAuth } from '../context/AuthContext';
import withTimeTracking from './Track/withTimeTracking';


const Home = () => {
    const navigate = useNavigate();
    const { authData } = useAuth();
    const token = authData.token;


    useEffect(() => {
        const verifyToken = async () => {
            try {
                await axios.get(`${process.env.REACT_APP_API_URL}/auth/verifytoken`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
            } catch (error) {
                // Pour tous les codes d'erreur 401, rediriger vers la page de connexion
                if (error.response && error.response.status === 401) {
                    navigate('/login');
                }
            }
        };
    
        verifyToken();
    }, [navigate]);
    

    return (
        <>
            <Card variant="outlined">
                <CardContent>
                    <Typography variant="h5" component="h2">
                        Vue d'ensemble du jeu interactif
                    </Typography>
                    <Typography variant="body2" component="p">
                        Voici quelques informations sur notre jeu interactif de cartes sur Twitch :
                        Des cartes interactives qui apparaissent pendant les streams en direct.
                        Interaction avec les cartes via le chat de Twitch.
                        Personnalisation des cartes et des decks.
                        Événements communautaires et tournois.
                        Statistiques de jeu en temps réel.
                    </Typography>
                </CardContent>
            </Card>

            <Card variant="outlined">
                <CardContent>
                    <Typography variant="h5" component="h2">
                        Nouveautés et mises à jour
                    </Typography>
                    <Typography variant="body2" component="p">
                        Restez à jour avec les dernières nouvelles et mises à jour :
                        Ajouts de fonctionnalités et améliorations.
                        Correctifs de bugs.
                        Événements spéciaux à venir.
                    </Typography>
                </CardContent>
            </Card>
        </>
    );
};

export default withTimeTracking(Home);;
