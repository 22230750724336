import React, { useEffect, useState } from 'react';
import axios from 'axios';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableSortLabel from '@mui/material/TableSortLabel';
import Paper from '@mui/material/Paper';
import TablePagination from '@mui/material/TablePagination';
import { useAuth } from '../context/AuthContext';

const RankingsDisplay = () => {
    const [rankings, setRankings] = useState([]);
    const [loading, setLoading] = useState(true);
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('initialRank');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(15); // Définir par défaut à 15 lignes par page
    const {authData} = useAuth(); // Utilisez useAuth pour obtenir les informations d'authentification
    const [usernameLogged, setUsernameLogged] = useState(authData.username); // Utilisez le nom d'utilisateur du site depuis useAuth
    const [userRank, setUserRank] = useState(null); // Classement de l'utilisateur

    useEffect(() => {
        const fetchRankings = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/Rank`);
                const rankedData = response.data.map((item, index) => ({
                    ...item,
                    initialRank: index + 1, // Ajoute le rang initial basé sur la position de l'item
                }));
                setRankings(rankedData);

                // Afficher les données pour le débogage
                console.log("Données classées :", rankedData);

                // Trouver le classement de l'utilisateur par son nom
                const userIndex = rankedData.findIndex((ranking) => ranking.usersSiteUsername === usernameLogged);
                console.log("Index de l'utilisateur :", userIndex);

                setUserRank(userIndex !== -1 ? userIndex + 1 : null);

                setLoading(false);
            } catch (error) {
                console.error("Erreur lors de la récupération du classement :", error);
                setLoading(false);
            }
        };

        fetchRankings();
    }, [usernameLogged]);

    const handleRequestSort = (property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const stableSort = (array, comparator) => {
        const stabilizedThis = array.map((el, index) => [el, index]);
        stabilizedThis.sort((a, b) => {
            const order = comparator(a[0], b[0]);
            if (order !== 0) return order;
            return a[1] - b[1];
        });
        return stabilizedThis.map((el) => el[0]);
    };

    const getComparator = (order, orderBy) => {
        return order === 'desc'
            ? (a, b) => descendingComparator(a, b, orderBy)
            : (a, b) => -descendingComparator(a, b, orderBy);
    };

    const descendingComparator = (a, b, orderBy) => {
        if (b[orderBy] < a[orderBy]) {
            return -1;
        }
        if (b[orderBy] > a[orderBy]) {
            return 1;
        }
        return 0;
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    if (loading) {
        return <div>Chargement des classements...</div>;
    }

    const sortedRankings = rankings.sort((a, b) => a.initialRank - b.initialRank);

    return (
        <>
            <h2>Classement des Joueurs</h2>
            <h3>Vous êtes classé {userRank !== null ? userRank : 'non classé'}</h3>
            <TableContainer component={Paper} sx={{ marginLeft: 'auto', marginRight: 'auto', maxWidth: '99%' }}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                <TableSortLabel
                                    active={orderBy === 'initialRank'}
                                    direction={orderBy === 'initialRank' ? order : 'asc'}
                                    onClick={() => handleRequestSort('initialRank')}
                                >
                                    Rang
                                </TableSortLabel>
                            </TableCell>
                            <TableCell>
                                <TableSortLabel
                                    active={orderBy === 'username'}
                                    direction={orderBy === 'username' ? order : 'asc'}
                                    onClick={() => handleRequestSort('username')}
                                >
                                    Nom d'Utilisateur(Jeu)
                                </TableSortLabel>
                            </TableCell>
                            <TableCell align="right">
                                <TableSortLabel
                                    active={orderBy === 'score'}
                                    direction={orderBy === 'score' ? order : 'asc'}
                                    onClick={() => handleRequestSort('score')}
                                >
                                    Score
                                </TableSortLabel>
                            </TableCell>
                            <TableCell>
                                <TableSortLabel
                                    active={orderBy === 'usersSiteUsername'}
                                    direction={orderBy === 'usersSiteUsername' ? order : 'asc'}
                                    onClick={() => handleRequestSort('usersSiteUsername')}
                                >
                                    Nom d'Utilisateur(Site)
                                </TableSortLabel>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {stableSort(sortedRankings, getComparator(order, orderBy))
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((ranking) => (
                                <TableRow hover tabIndex={-1} key={ranking.userId}>
                                    <TableCell component="th" scope="row" padding="none" sx={{ paddingLeft: '16px' }}>
                                        {ranking.initialRank}
                                    </TableCell>
                                    <TableCell align="left">{ranking.username}</TableCell>
                                    <TableCell align="right">{ranking.score}</TableCell>
                                    <TableCell
                                        align="left"
                                        sx={{
                                            color: ranking.usersSiteUsername === 'Compte non lié' ? 'red' : ranking.usersSiteUsername ? 'green' : 'inherit'
                                        }}
                                    >
                                        {ranking.usersSiteUsername}
                                    </TableCell>
                                </TableRow>
                            ))}
                    </TableBody>
                </Table>
                <TablePagination
                    labelRowsPerPage="Résultats par page :"
                    labelDisplayedRows={({ from, to, count }) => `Affichage de ${from} à ${to} sur ${count}`}
                    rowsPerPageOptions={[15, 25, 50]}
                    component="div"
                    count={rankings.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </TableContainer>
        </>
    );
};

export default RankingsDisplay
