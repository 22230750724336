import React, { useEffect, useContext } from 'react';
import axios from 'axios';
import { useAuth } from '../../context/AuthContext'; // Assurez-vous que le chemin d'accès est correct

const withTimeTracking = WrappedComponent => {
  return function TimeTrackedComponent(props) {
    const { authData } = useAuth(); // Utilisation du hook useContext pour accéder à useAuth
    const token = authData?.token;

    useEffect(() => {
      const startTime = Date.now();

      return () => {
        const endTime = Date.now();
        const timeSpentMs = endTime - startTime; // Temps passé en millisecondes
        const timeSpentSeconds = Math.floor(timeSpentMs / 1000); // Conversion en secondes

        // Ne pas envoyer les données si le temps passé est inférieur à 1 seconde
        if (timeSpentSeconds > 0) {
          console.log(`Temps passé : ${timeSpentSeconds} secondes`);
          const config = {
            headers: {
              Authorization: `Bearer ${token}`
            }
          };
          // Remplacez l'URL ci-dessous par l'endpoint de votre API
          axios.post(`${process.env.REACT_APP_API_URL}/Tracker/UpdateTimeSpent`, { TimeSpent: timeSpentSeconds }, config)
            .then(response => console.log(response.data, 'Temps passé envoyé avec succès'))
            .catch(error => console.error('Erreur lors de l\'envoi du temps passé', error));
        }
      };
    }, [token]); // Ajoutez token aux dépendances de useEffect pour s'exécuter à nouveau si token change

    // Rendre le composant enveloppé avec les props passés à lui
    return <WrappedComponent {...props} />;
  };
};

export default withTimeTracking;
