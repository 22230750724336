// src/context/AuthContext.js
import React, { createContext, useState, useContext } from 'react';

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
    const [authData, setAuthData] = useState(JSON.parse(localStorage.getItem('authData')));

    const login = (data) => {
        setAuthData(data);
        localStorage.setItem('authData', JSON.stringify(data));
    };

    const logout = () => {
        setAuthData(null);
        localStorage.removeItem('authData');
    };
    const role = (newRole) => {
        const newData = { ...authData, role: newRole };
        setAuthData(newData);
        localStorage.setItem('authData', JSON.stringify(newData));
    };



    return (
        <AuthContext.Provider value={{ authData, setAuthData, login, logout, role }}>
        {children}
    </AuthContext.Provider>
    );
};
