import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { Avatar, Box, Button, Card, CardContent, Grid, IconButton, Modal, TextField, Tooltip, Typography, Link } from '@mui/material';
import { purple } from '@mui/material/colors';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import EditIcon from '@mui/icons-material/Edit';
import { useAuth } from '../context/AuthContext';
import { useTheme } from "@mui/material/styles";
import { tokens } from "../theme";
import { UsernameContext } from '../context/UsernameContext';
import Pie from '../scenes/pie';
import TwitchLoginButton from './twitch/TwitchLoginButton';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Divider from '@mui/material/Divider';
import GraphuserTimeTotalTimeG from './GraphuserTimeTotalTimeG';
import LogBook from './LogBook';

function formatDate(dateString) {
    const date = new Date(dateString);
    return date.toLocaleString('fr-FR', {
      year: 'numeric', month: 'long', day: 'numeric',
      hour: '2-digit', minute: '2-digit', second: '2-digit',
      hour12: false
    });
  }

const UserAccount = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [showOverlay, setShowOverlay] = useState(false);
    const [openAvatarModal, setOpenAvatarModal] = useState(false);
    const [openEmailModal, setOpenEmailModal] = useState(false);
    const [openUsernameModal, setOpenUsernameModal] = useState(false);
    const [openPasswordModal, setOpenPasswordModal] = useState(false); // New state for password modal
    const { authData } = useAuth();
    const [userData, setUserData] = useState({}); // Initialize user data state
    const [newUsername, setNewUsername] = useState(''); // New state for the new username
    const [newEmail, setNewEmail] = useState(''); // New state for the new email
    const { setUsername } = useContext(UsernameContext);
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmNewPassword, setConfirmNewPassword] = useState('');
    const [alertInfo, setAlertInfo] = useState({ severity: '', message: '', open: false });
    const [isGameAccountLinked, setIsGameAccountLinked] = useState(false);
    const [openLinkAccountModal, setOpenLinkAccountModal] = useState(false);
    const [codelink, setCodelink] = useState('');
    const [gameAccountData, setGameAccountData] = useState(null);



    useEffect(() => {
        // Assurez-vous d'ajouter le token JWT à l'en-tête de la requête
        const token = authData.token;
        axios.get(`${process.env.REACT_APP_API_URL}/User/profile`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then(response => {
                setUserData(response.data); // Update user data state
                console.log(response.data)
                setIsGameAccountLinked(response.data.isGameAccountLinked); // Update isGameAccountLinked state
                setCodelink(response.data.linkcode); // Update codelink state
            })
            .catch(error => {
                console.error('Il y avait une erreur!', error);
            });
    }, []);

    useEffect(() => {
        const token = authData.token;
        axios.get(`${process.env.REACT_APP_API_URL}/User/GetUserAccountGame`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then(response => {
                console.log('Compte de jeu lié:', response.data);
                setGameAccountData(response.data); // Stocke les données du compte de jeu
                setIsGameAccountLinked(true); // Ceci est supposé être mis à jour en fonction de la réponse, donc assurez-vous qu'il est correctement défini
            })
            .catch(error => {
                console.error('Error compte de jeu', error);
            });
    }, []);




    const styles = {
        title: {
            title: {
                margin: '',
                color: purple[500],
                fontWeight: 'normal',
                textAlign: 'center',

            },
        }
    }

    const modalStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        bgcolor: colors.primary[400],
        boxShadow: 24,
        p: 4,
    };
    userData.avatarUrl = `https://example.com/avatar.jpg`;
    const showAlert = (severity, message) => {
        setAlertInfo({ severity, message, open: true });

    };
    const closeAlert = () => {
        setAlertInfo({ ...alertInfo, open: false });
    };

    const handleUsernameChange = (event) => {
        event.preventDefault();
        const token = authData.token; // Assurez-vous d'ajouter le token JWT à l'en-tête de la requête

        axios.post(`${process.env.REACT_APP_API_URL}/User/UpdateUsername`, {
            NewUsername: newUsername // Assurez-vous que le nom de la propriété correspond à celui attendu par votre API
        }, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then(response => {
                console.log('Nom d\'utilisateur changé avec succès!');
                setUserData(prevState => ({ ...prevState, username: newUsername })); // Update username in the state
                setUsername(newUsername); // Update username in the context
                setOpenUsernameModal(false);
                showAlert('success', 'Nom d\'utilisateur changé avec succès!');
            })
            .catch(error => {
                // On vérifie si c'est une erreur 400 et que le message est celui attendu
                if (error.response && error.response.status === 400 && error.response.data === 'The username is already taken.') {
                    showAlert('error', 'Le nom d\'utilisateur est déjà pris.');
                } else {
                    // Si ce n'est pas une erreur 400 ou si le message d'erreur est différent, on affiche un message générique
                    showAlert('error', 'Il y avait une erreur lors du changement de nom d\'utilisateur.');
                }
                console.error('Il y avait une erreur!', error);
            });
    };


    const handleEmailChange = async (event) => {
        event.preventDefault();
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_API_URL}/User/UpdateUserEmail`,
                { NewEmail: newEmail },
                { headers: { Authorization: `Bearer ${authData.token}` } }
            );
            console.log('Email updated successfully:', response.data);
            setOpenEmailModal(false);
            setUserData(prev => ({ ...prev, email: newEmail }));
            showAlert('success', response.data.message);
        } catch (error) {
            const errorMessage = error.response?.data || 'Erreur inattendue lors de la mise à jour de l\'email.';
            console.error('Error updating email:', errorMessage);
            showAlert('error', errorMessage);
        }
    };

    const handlePasswordChange = async (event) => {
        event.preventDefault();
        if (newPassword !== confirmNewPassword) {
            showAlert('error', 'Les nouveaux mots de passe ne correspondent pas.');
            return;
        }
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_API_URL}/User/UpdatePassword`,
                { OldPassword: oldPassword, NewPassword: newPassword },
                { headers: { Authorization: `Bearer ${authData.token}` } }
            );
            console.log('Password updated successfully:', response.data);
            setOpenPasswordModal(false);
            setOldPassword('');
            setNewPassword('');
            setConfirmNewPassword('');
            showAlert('success', 'Mot de passe mis à jour avec succès.');
        } catch (error) {
            const errorMessage = error.response?.data || 'Erreur inattendue lors de la mise à jour du mot de passe.';
            console.error('Error updating password:', errorMessage);
            showAlert('error', errorMessage);
        }
    };

    return (
        <>
            <Snackbar
                open={alertInfo.open}
                autoHideDuration={6000}
                onClose={closeAlert}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
                <Alert
                    onClose={closeAlert}
                    severity={alertInfo.severity}
                    variant="error"
                    sx={{ width: '100%' }}
                >
                    {alertInfo.message}
                </Alert>
            </Snackbar>
            <Box sx={{ flexGrow: 1, mt: 3, ml: { sm: `20px` }, mr: { sm: `20px` } }}>
                <Grid container spacing={2}>

                    <Grid item xs={12} sm={4}>
                        <Card sx={{ backgroundColor: colors.primary[400], height: '100%' }}>
                            <CardContent>
                                <Typography variant="h3" gutterBottom sx={{ color: purple[500], textAlign: 'center' }}>
                                    Vos informations
                                </Typography>
                                <Divider variant="middle" sx={{ my: 2, mx: 'auto', width: '80%', marginBottom: '20px' }} />
                                <Box
                                    sx={{
                                        position: 'relative',
                                        width: 90,
                                        height: 90,
                                        margin: 'auto',
                                        borderRadius: '50%',
                                        backgroundColor: colors.primary[400],
                                    }}
                                    onMouseEnter={() => setShowOverlay(true)}
                                    onMouseLeave={() => setShowOverlay(false)}

                                >
                                    <Avatar
                                        alt={userData.username}
                                        src={userData.avatarUrl}
                                        sx={{ width: 90, height: 90, opacity: showOverlay ? 0.5 : 1 }}
                                    />
                                    <Box
                                        sx={{
                                            position: 'absolute',
                                            top: 0,
                                            left: 0,
                                            width: '100%',
                                            height: '100%',
                                            bgcolor: 'rgba(0, 0, 0, 0.5)',
                                            color: 'white',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            visibility: showOverlay ? 'visible' : 'hidden',
                                            borderRadius: '50%',
                                            textAlign: 'center',
                                        }}
                                    >
                                        Changer d'avatar
                                    </Box>
                                    <Tooltip title="Changer d'avatar" placement="right">
                                        <IconButton
                                            id="change-avatar-button"
                                            sx={{
                                                position: 'absolute',
                                                right: -8,
                                                bottom: -8,
                                                bgcolor: purple[500],
                                                color: 'white',
                                                visibility: showOverlay ? 'visible' : 'hidden',
                                                '&:hover': {
                                                    bgcolor: purple[700],
                                                },
                                            }}
                                            onClick={() => setOpenAvatarModal(true)}
                                        >
                                            <CameraAltIcon />
                                        </IconButton>
                                    </Tooltip>
                                </Box>
                                <Typography variant="h6" gutterBottom sx={{ textAlign: 'center', mt: 2 }}>
                                    {userData.username}
                                    <IconButton size="small" onClick={() => setOpenUsernameModal(true)}>
                                        <EditIcon fontSize="inherit" />
                                    </IconButton>
                                </Typography>
                                <Typography color="textSecondary" sx={{ textAlign: 'center' }}>
                                    {userData.email}
                                    <IconButton size="small" onClick={() => setOpenEmailModal(true)}>
                                        <EditIcon fontSize="inherit" />
                                    </IconButton>
                                </Typography>
                                <Button variant="contained" color="primary" fullWidth sx={{ mt: 1, bgcolor: purple[500] }} onClick={() => setOpenPasswordModal(true)}>
                                    Changer le mot de passe
                                </Button>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Card sx={{ backgroundColor: colors.primary[400], height: '100%' }}>
                            <CardContent>
                                <Typography variant="h3" gutterBottom sx={{ color: purple[500], textAlign: 'center' }}>
                                    Votre compte de jeu
                                </Typography>

                                {/* Modifier le Divider ici */}
                                <Divider variant="middle" sx={{ my: 2, mx: 'auto', width: '80%', marginBottom: '20px' }} />
                                {isGameAccountLinked && gameAccountData ? (
      <>
        <Box sx={{ textAlign: "center" }}>

  {isGameAccountLinked && gameAccountData ? (
    <Box >
      <Typography variant="h4" sx={{ mb: 2, fontWeight: 'bold', color: colors.greenAccent[400], textAlign: "center" }}>
        Compte de jeu lié
      </Typography>
      <Typography variant="h6" sx={{ mb: 1 }}>
        Nom d'utilisateur: <Box component="span" sx={{ fontWeight: 'bold', fontStyle: 'italic' }}>{gameAccountData.username}</Box>
      </Typography>
      <Typography variant="h6" sx={{ mb: 1 }}>
        Nombre de coins: <Box component="span" sx={{ fontWeight: 'bold', fontStyle: 'italic' }}>{gameAccountData.numberCoins}</Box>
      </Typography>
      <Typography variant="h6" sx={{ mb: 1 }}>
        Nombre de cartes: <Box component="span" sx={{ fontWeight: 'bold', fontStyle: 'italic' }}>{gameAccountData.numberOfCards}</Box>
      </Typography>
      <Typography variant="h6" sx={{ mb: 1 }}>
        Nombre d'items: <Box component="span" sx={{ fontWeight: 'bold', fontStyle: 'italic' }}>{gameAccountData.numberOfItems}</Box>
      </Typography>
      <Typography variant="h6" sx={{ mb: 1 }}>
        Date de votre dernier message sur le stream: <Box component="span" sx={{ fontWeight: 'bold', fontStyle: 'italic' }}>{formatDate(gameAccountData.lastMessageSent)}</Box>
      </Typography>
    </Box>
  ) : (
    <Typography variant="body1" sx={{ color: 'white', textAlign: 'center' }}>
      Compte de jeu non lié
    </Typography>
  )}
</Box>

      </>
    ) : (
                                    <>
                                        <Box sx={{ marginTop: '65px', marginBottom: '100px' }}>
                                            <Typography variant="h4" sx={{ color: 'red', textAlign: 'center', mb: 2 }}>
                                                Compte de jeu non lié
                                            </Typography>
                                        </Box>
                                        <Button variant="contained" color="primary" fullWidth sx={{ bgcolor: purple[500], mb: 2 }} onClick={() => setOpenLinkAccountModal(true)}>
                                            Comment lier son compte
                                        </Button>
                                        <Typography variant="body2" sx={{ color: 'white', textAlign: 'center', fontStyle: 'italic' }}>
                                            Pour accéder à toutes les fonctionnalités du site, il est important de lier votre compte de jeu. Cela permet une expérience utilisateur plus riche et personnalisée.
                                        </Typography>
                                    </>
                                )}

                            </CardContent>
                        </Card>
                    </Grid>


                    <Grid item xs={12} sm={4}>
                        <Card sx={{ backgroundColor: colors.primary[400], height: '100%' }}>
                            <Typography variant="h3" gutterBottom sx={{ color: purple[500], textAlign: 'center', marginTop: '20px' }}>
                                Répartition du Temps d'Utilisation
                            </Typography>
                            <Divider variant="middle" sx={{ my: 2, mx: 'auto', width: '80%', marginBottom: '20px' }} />

                            <GraphuserTimeTotalTimeG />

                        </Card>
                    </Grid>
                    <Grid item xs={12}>
                        <Card sx={{ backgroundColor: colors.primary[400] }}>
                            <LogBook />
                        </Card>
                    </Grid>


                    <Modal open={openEmailModal} onClose={() => setOpenEmailModal(false)} aria-labelledby="modal-email-title">
                        <Box sx={modalStyle}>
                            <Typography id="modal-email-title" variant="h6" component="h2">
                                Changer l'email
                            </Typography>
                            <Box component="form" noValidate autoComplete="off" sx={{ mt: 2 }} onSubmit={handleEmailChange}>
                                <TextField
                                    label="Nouvel email"
                                    type="email"
                                    fullWidth
                                    sx={{ mb: 2 }}
                                    value={newEmail}
                                    onChange={(e) => setNewEmail(e.target.value)}
                                    required
                                />
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    fullWidth
                                    sx={{ mt: 1, bgcolor: purple[500] }}
                                >
                                    Valider
                                </Button>
                            </Box>
                        </Box>
                    </Modal>
                    <Modal open={openUsernameModal} onClose={() => setOpenUsernameModal(false)} aria-labelledby="modal-username-title">
                        <Box sx={modalStyle}>
                            <Typography id="modal-username-title" variant="h6" component="h2">
                                Changer le nom d'utilisateur
                            </Typography>
                            <Box component="form" noValidate autoComplete="off" sx={{ mt: 2 }} onSubmit={handleUsernameChange}>
                                <TextField label="Nouveau nom d'utilisateur" fullWidth sx={{ mb: 2 }} value={newUsername} onChange={(e) => setNewUsername(e.target.value)} />
                                <Button variant="contained" color="primary" fullWidth sx={{ mt: 1, bgcolor: purple[500] }} type="submit">
                                    Valider
                                </Button>
                            </Box>
                        </Box>
                    </Modal>
                    <Modal open={openPasswordModal} onClose={() => setOpenPasswordModal(false)} aria-labelledby="modal-password-title">
                        <Box sx={modalStyle}>
                            <Typography id="modal-password-title" variant="h6" component="h2">
                                Changer le mot de passe
                            </Typography>
                            <Box component="form" noValidate autoComplete="off" sx={{ mt: 2 }} onSubmit={handlePasswordChange}>
                                <TextField type="password" label="Ancien mot de passe" fullWidth sx={{ mb: 2 }} value={oldPassword} onChange={(e) => setOldPassword(e.target.value)} />
                                <TextField type="password" label="Nouveau mot de passe" fullWidth sx={{ mb: 2 }} value={newPassword} onChange={(e) => setNewPassword(e.target.value)} />
                                <TextField type="password" label="Confirmer le nouveau mot de passe" fullWidth sx={{ mb: 2 }} value={confirmNewPassword} onChange={(e) => setConfirmNewPassword(e.target.value)} />
                                <Button type="submit" variant="contained" color="primary" fullWidth sx={{ mt: 1, bgcolor: purple[500] }}>
                                    Valider
                                </Button>
                            </Box>
                        </Box>
                    </Modal>

                    <Modal open={openAvatarModal} onClose={() => setOpenAvatarModal(false)} aria-labelledby="modal-avatar-title">
                        <Box sx={modalStyle}>
                            <Typography id="modal-avatar-title" variant="h6" component="h2">
                                Changer l'avatar
                            </Typography>
                            <Box component="form" noValidate autoComplete="off" sx={{ mt: 2 }}>
                                <TextField type="file" label="Nouvel avatar" fullWidth sx={{ mb: 2 }} />
                                <Typography color="error" variant="body2">
                                    Cette fonctionnalité est prévue et arrivera prochainement.
                                </Typography>
                                <Button variant="contained" color="primary" fullWidth sx={{ mt: 1, bgcolor: purple[500] }}>
                                    Valider
                                </Button>
                            </Box>
                        </Box>
                    </Modal>
                    <Modal open={openLinkAccountModal} onClose={() => setOpenLinkAccountModal(false)} aria-labelledby="modal-link-account-title">
                        <Box sx={modalStyle}>
                            <Typography id="modal-link-account-title" variant="h4" component="h2" sx={{ textAlign: "center", color: purple[400] }}>
                                Comment lier votre compte de jeu
                            </Typography>
                            <Box sx={{ mt: 2 }}>
                                <Typography paragraph>
                                    Suivez ces étapes pour lier votre compte de jeu :
                                </Typography>
                                <Typography paragraph>
                                    1. Copier coller ce code : <Box component="code" sx={{
                                        backgroundColor: 'black',
                                        color: 'white',
                                        p: 0.5,
                                        borderRadius: 1,
                                        fontSize: '1rem' // Vous pouvez ajuster cette valeur selon vos besoins
                                    }}>
                                        !link {codelink}
                                    </Box>
                                </Typography>
                                <Typography paragraph>
                                    2. Assurez-vous d'être connecté à votre compte Twitch.
                                </Typography>
                                <Typography paragraph>
                                    3. Assurez-vous que Pixetmotion soit en live pour la suite des instructions.
                                </Typography>
                                <Typography paragraph>
                                    4. Rendez-vous sur le stream de <Link sx={{ fontSize: '1.5rem' }} href="https://www.twitch.tv/pixetmotion" color={purple[400]} underline="hover" target="_blank" rel="noopener">PixEtMotion</Link> et collez la commande précédemment copiée dans le chat.
                                </Typography>
                                <Typography paragraph>
                                    5. Votre compte de jeu est maintenant lié à votre compte utilisateur. Actualiser la page !
                                </Typography>
                                <Button variant="contained" color="primary" fullWidth sx={{ mt: 1, bgcolor: purple[500] }} onClick={() => setOpenLinkAccountModal(false)}>
                                    Compris
                                </Button>
                            </Box>
                        </Box>
                    </Modal>
                </Grid>
            </Box>
        </>
    );
};

export default UserAccount;