import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Card, CardContent, CardMedia, Typography, Grid, Container, CardActions, Button, IconButton, Box, Stack, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import InfoIcon from '@mui/icons-material/Info';
import { useTheme } from "@mui/material/styles";
import { tokens } from "../theme";
import { green, deepPurple, indigo, purple, red } from '@mui/material/colors';
import Divider from '@mui/material/Divider';
import { useAuth } from '../context/AuthContext';
import { useNavigate } from 'react-router-dom';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';


const Boutique = () => {
    const [userCoins, setUserCoins] = useState(0);
    const [items, setItems] = useState([]);
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const { authData } = useAuth();
    const token = authData.token;
    const navigate = useNavigate();

    const [openModal, setOpenModal] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);
    const [alertInfo, setAlertInfo] = useState({ severity: '', message: '', open: false });
    const showAlert = (severity, message) => {
        setAlertInfo({ severity, message, open: true });
        
    };
    const closeAlert = () => {
        setAlertInfo({ ...alertInfo, open: false });
    };
    useEffect(() => {
        const verifyToken = async () => {
            try {
                await axios.get(`${process.env.REACT_APP_API_URL}/auth/verifytoken`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
            } catch (error) {
                // Pour tous les codes d'erreur 401, rediriger vers la page de connexion
                if (error.response && error.response.status === 401) {
                    navigate('/login');
                }
            }
        };

        verifyToken();
    }, [navigate]);

    useEffect(() => {
        const fetchItems = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/boutique`);
                setItems(response.data);
            } catch (error) {
                console.error("Erreur lors de la récupération des items:", error);
            }
        };

        fetchItems();
    }, []);
    useEffect(() => {
        const fetchUserCoins = async () => {
            try {
                const config = {
                    headers: { Authorization: `Bearer ${authData.token}` },
                };
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/User/GetUserPoints`, config);
                console.log("Coins de l'utilisateur:", response.data);
                let coins = response.data[0].nombrePoints; // Accédez à la première position du tableau
                setUserCoins(coins);
            } catch (error) {
                console.error("Erreur lors de la récupération des coins de l'utilisateur:", error);
            }
        };

        fetchUserCoins();
    }, [authData.token]);


    const handleOpenModal = (item) => {
        console.log(item);
        setSelectedItem(item);
        setOpenModal(true);
    };

    const handleCloseModal = () => {
        setOpenModal(false);
    };

    const handleBuyItem = async () => {
        if (!selectedItem || selectedItem.itemId === undefined) {
            showAlert('error', 'Impossible de trouver l\'item sélectionné');
            return;
        }

        try {
            const config = {
                headers: { Authorization: `Bearer ${authData.token}` },
            };
            // Assurez-vous d'utiliser selectedItem.itemId ici
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/Boutique/Buy`, { itemId: selectedItem.itemId }, config);
            showAlert('success', 'Achat effectué avec succès');
            if (selectedItem && selectedItem.price) {
                setUserCoins((prevCoins) => prevCoins - selectedItem.price);
            }
            setOpenModal(false);
        } catch (error) {
            console.error("Erreur lors de l'enregistrement de l'achat:", error);
        }
    };



    const handleActionClick = (item, action) => {
        console.log(`Action ${action} pour l'item:`, item.name);
        if (action === 'acheter') {
            handleOpenModal(item);
        }
    };

    return (
        <>
            <Snackbar
                open={alertInfo.open}
                autoHideDuration={6000}
                onClose={closeAlert}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
                <Alert
                    onClose={closeAlert}
                    severity={alertInfo.severity}
                    variant="filled"
                    sx={{ width: '100%' }}
                >
                    {alertInfo.message}
                </Alert>
            </Snackbar>
        <Container maxWidth="md" sx={{ mt: 4, mb: 4 }}>
            <Box textAlign="center" sx={{ mb: 4 }}>
                <Typography variant="h4" gutterBottom>
                    Vos coins: {userCoins}
                </Typography>
            </Box>
            <Typography gutterBottom variant="h3" component="h1" textAlign="center" color={indigo[500]} sx={{ mb: 4 }}>
                Boutique
            </Typography>


            <Grid container spacing={5} >
                {items.map((item, index) => (
                    <Grid item key={index} xs={12} sm={6} md={4} >
                        <Card
                            sx={{
                                maxWidth: 345,
                                transition: 'transform 0.15s ease-in-out',
                                position: 'relative',
                                '&:hover': {
                                    transform: 'scale3d(1.05, 1.05, 1)',
                                    boxShadow: theme.shadows[20],
                                    // Ciblez le bouton d'info lorsque la carte est survolée pour changer son opacité
                                    '& .infoButton': {
                                        visibility: 'visible',
                                    },
                                    bgcolor: colors.primary[800]
                                }
                            }}
                        >
                            <CardMedia
                                component="img"
                                height="200"
                                image={`${process.env.PUBLIC_URL}/items/${item.name.replace(/\s+/g, '_')}.png`}
                                alt={item.name}
                                sx={{ opacity: 0.85, '&:hover': { opacity: 1 }, bgcolor: colors.primary[400]}}
                            />
                            <CardContent sx={{backgroundColor: colors.primary[400] }}>
                                <Typography gutterBottom variant="h6" component="h2" color={theme.palette.secondary.dark} textAlign='center'>
                                    {item.name}
                                    <Divider />
                                </Typography>
                                <Typography variant="body2" color="text.secondary" sx={{ mb: 2, fontStyle: 'italic' }}>
                                    {item.description}
                                </Typography>
                                <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={1}>
                                    <Typography variant="body2" color={green[600]}>
                                        Prix: <Box component="span" sx={{ fontWeight: 'bold' }}>{item.price}</Box>
                                    </Typography>
                                    <Typography variant="body2" color={deepPurple[400]}>
                                        Quantité: <Box component="span" sx={{ fontWeight: 'bold' }}>{item.quantity === null ? 'Illimité' : item.quantity}</Box>
                                    </Typography>
                                </Stack>
                            </CardContent>
                            <CardActions disableSpacing sx={{ justifyContent: 'center', pt: 0, backgroundColor: colors.primary[400]}}>
                                <Button
                                    startIcon={<ShoppingCartIcon />}
                                    variant="contained"
                                    onClick={() => handleActionClick(item, 'acheter')}
                                    sx={{ backgroundColor: green[400], '&:hover': { backgroundColor: purple[600] } }}
                                >
                                    Acheter
                                </Button>
                                <IconButton
                                    onClick={() => handleActionClick(item, 'info')}
                                    className='infoButton'
                                    sx={{
                                        position: 'absolute',
                                        top: 10,
                                        right: 10,
                                        width: 30,
                                        height: 30,
                                        borderRadius: '50%',
                                        backgroundColor: '#007bff',
                                        color: '#ffffff',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        fontSize: '1.2rem',
                                        cursor: 'pointer',
                                        boxShadow: '0 2px 4px rgba(0,0,0,0.2)',
                                        visibility: 'hidden', // Invisible par défaut
                                        transition: 'visibility 0.3s linear', // Transition pour visibilité
                                        '&:hover': {
                                            backgroundColor: purple[400],
                                        }
                                    }}
                                >
                                    <InfoIcon />
                                </IconButton>
                            </CardActions>
                        </Card>
                    </Grid>
                ))}
            </Grid>

            <Dialog
                open={openModal}
                onClose={handleCloseModal}
                aria-labelledby="confirmation-dialog-title"
                aria-describedby="confirmation-dialog-description"
                maxWidth="sm"
                fullWidth
                style={{ bgcolor: colors.primary[400]}}
                
            >
                <DialogTitle sx={{ color: purple[400], bgcolor: colors.primary[400] }} textAlign={'center'} variant="h3" id="confirmation-dialog-title">Confirmer l'achat</DialogTitle>
                <DialogContent dividers sx={{bgcolor: colors.primary[400]}}>
                    <Card sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' } }}>
                        <CardMedia
                            component="img"
                            sx={{ width: 151, height: 151, margin: 'auto'}}
                            image={`${process.env.PUBLIC_URL}/items/${selectedItem?.name.replace(/\s+/g, '_')}.png`}
                            alt={selectedItem?.name}
                        />
                        <Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
                            <CardContent sx={{ flex: '1 0 auto'}}>
                                <Typography component="div" variant="h5" color={theme.palette.secondary.dark}>
                                    {selectedItem?.name}
                                </Typography>
                                <Typography variant="subtitle1" color="text.secondary" component="div">
                                    {selectedItem?.description}
                                </Typography>
                                <Typography variant="body2" sx={{ mt: 2, color: colors.redAccent[500] }}>
                                    Coût: {selectedItem?.price} coins
                                </Typography>
                                <Typography variant="body2" sx={{ mt: 1 }}>
                                    Vos coins après l'achat: {userCoins - selectedItem?.price} coins
                                </Typography>
                            </CardContent>
                        </Box>
                    </Card>
                </DialogContent>
                <DialogActions sx={{bgcolor: colors.primary[400]}}>
                    <Button sx={{ color: red[500] }} onClick={handleCloseModal}>Annuler</Button>
                    <Button disabled={userCoins < selectedItem?.price} sx={{ color: green[500] }} onClick={() => {
                        handleBuyItem(selectedItem);
                    }} autoFocus>
                        {userCoins < selectedItem?.price ? "Fonds insuffisants" : "Acheter"}
                    </Button>
                </DialogActions>
            </Dialog>
        </Container>
        </>
    );
};

export default Boutique;