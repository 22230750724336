import { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import Topbar from "./scenes/global/Topbar";
import Sidebar from "./scenes/global/Sidebar";
import FAQ from "./scenes/faq";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { ColorModeContext, useMode } from "./theme";
import PrivateRoute from "./context/PrivateRoute";
import { AuthProvider } from "./context/AuthContext";
import Welcome from "./components/Welcome";
import Login from "./components/Login";
import Register from "./components/Register";
import Home from "./components/Home";
import CardsDisplay from "./components/CardsDisplay";
import UserCardsDisplay from "./components/UserCardsDisplay";
import RankingsDisplay from "./components/RankingsDisplay";
import Boutique from "./components/Boutique";
import UserAccount from "./components/UserAccount";
import { UsernameContext } from "./context/UsernameContext";

function App() {
  const location = useLocation();
  const [theme, colorMode] = useMode();
  const [isSidebar, setIsSidebar] = useState(false);
  const [username, setUsername] = useState('');

  useEffect(() => {
    setIsSidebar(location.pathname !== "/" && location.pathname !== "/login" && location.pathname !== "/register");
  }, [location]);

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <AuthProvider>
        <UsernameContext.Provider value={{ username, setUsername }}>
          <CssBaseline />
          <div className="app">
            {isSidebar && <Sidebar />}
            <main className="content">
              <Topbar setIsSidebar={setIsSidebar} />
              <Routes>
                <Route path="/" element={<Welcome />} />
                <Route path="/login" element={<Login />} />
                <Route path="/register" element={<Register />} />
                <Route path="/faq" element={<FAQ />} />
                <Route path="/home" element={<PrivateRoute><Home /></PrivateRoute>} />
                <Route path="/listCard" element={<PrivateRoute><CardsDisplay /></PrivateRoute>} />
                <Route path="/MyInventoryCards" element={<PrivateRoute><UserCardsDisplay /></PrivateRoute>} />
                <Route path="ranking" element={<PrivateRoute><RankingsDisplay /> </PrivateRoute>} />
                <Route path="boutique" element={<PrivateRoute><Boutique /></PrivateRoute>} />
                <Route path="/userAccount" element={<PrivateRoute><UserAccount /></PrivateRoute>} />

                <Route path="*" element={<Navigate to="/" />} />
              </Routes>
            </main>
          </div>
          </UsernameContext.Provider>
        </AuthProvider>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

export default App;
