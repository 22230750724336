import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import { Button, TextField, Typography, Paper } from '@mui/material';
import { styled } from '@mui/system';
import { purple } from '@mui/material/colors';

const FullHeightContainer = styled('div')(({ theme }) => ({
    backgroundColor: '#333',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    width: '100vw',
    margin: 0,
    padding: theme.spacing(2),
    boxSizing: 'border-box',
}));

const CenteredPaper = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: theme.spacing(2),
    marginTop: theme.spacing(8),
}));

const StyledButton = styled(Button)({
    backgroundColor: purple[500],
    color: '#fff',
    '&:hover': {
        backgroundColor: purple[700],
    },
    marginTop: '20px',
    width: '100%',
});

const StyledTextField = styled(TextField)({
    '& label.Mui-focused': {
        color: purple[500],
    },
    '& .MuiInput-underline:after': {
        borderBottomColor: purple[900],
    },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: purple[600],
        },
        '&:hover fieldset': {
            borderColor: purple[900],
        },
        '&.Mui-focused fieldset': {
            borderColor: purple[500],
        },
    },
    '& .MuiInputBase-input': {
        color: purple[500],
    },
    width: '100%',
});

const StyledTypography = styled(Typography)(({ theme }) => ({
    position: 'relative',
    '&:after': {
        content: '""',
        position: 'absolute',
        bottom: 0,
        left: 0,
        width: '100%',
        height: '2px',
        backgroundColor: purple[500],
    },
    paddingBottom: theme.spacing(1),
}));

function Login() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();
    const { login } = useAuth();

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/auth/login`, {
                email,
                password,
            });

            if (response.data.token) {
                login(response.data);
                navigate('/home');
            } else {
                alert('Connexion échouée');
            }
        } catch (error) {
            console.error('Erreur lors de la connexion:', error);
            alert('Erreur lors de la connexion: ' + (error.response?.data?.message || error.message));
        }
    };

    return (
        <FullHeightContainer>
            <CenteredPaper elevation={6}>
                <StyledTypography variant="h4" component="h1" gutterBottom>
                    Connexion
                </StyledTypography>
                <form onSubmit={handleSubmit} style={{ width: '100%', maxWidth: '400px' }}>
                    <StyledTextField
                        type="email"
                        label="Email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                        margin="normal"
                    />
                    <StyledTextField
                        type="password"
                        label="Mot de passe"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                        margin="normal"
                    />
                    <StyledButton type="submit">
                        Se connecter
                    </StyledButton>
                    <StyledButton onClick={() => navigate('/register')}>
                        S'inscrire
                    </StyledButton>
                </form>
            </CenteredPaper>
        </FullHeightContainer>
    );
}

export default Login;
