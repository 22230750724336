import { tokens } from "../theme";

import React from 'react';
import { Container, Grid, Typography, Card, Avatar, Button, IconButton, Box } from '@mui/material';
import { styled } from '@mui/system';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { purple } from '@mui/material/colors';
import ComputerIcon from '@mui/icons-material/Computer';
import BrushIcon from '@mui/icons-material/Brush';
import FacebookIcon from '@mui/icons-material/Facebook';
import MusicNoteIcon from '@mui/icons-material/MusicNote';
import ChatIcon from '@mui/icons-material/Chat';
import InstagramIcon from '@mui/icons-material/Instagram';

const Welcome = () => {
    const navigate = useNavigate();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const StyledContainer = styled(Container)(({ theme }) => ({
        backgroundColor: theme.palette.background.default,
        minHeight: '100vh',
        padding: theme.spacing(4, 0),
    }));

    const Banner = styled(Box)(({ theme }) => ({
        backgroundColor: theme.palette.background.paper,
        color: theme.palette.text.primary,
        padding: theme.spacing(8, 4),
        textAlign: 'center',
        borderRadius: '20px',
        marginBottom: theme.spacing(6),
        border: `2px solid ${purple[400]}`,
        boxShadow: '0 4px 20px rgba(0,0,0,0.1)',
    }));

    const StyledButton = styled(Button)(({ theme }) => ({
        backgroundColor: purple[400],
        color: '#fff',
        padding: theme.spacing(1.5, 4),
        fontSize: '1.1rem',
        fontWeight: 'bold',
        '&:hover': {
            backgroundColor: purple[600],
            transform: 'translateY(-2px)',
            boxShadow: '0 4px 20px rgba(0,0,0,0.2)',
        },
        transition: 'all 0.3s ease',
    }));

    const Video = styled('iframe')({
        width: '100%',
        height: '350px',
        border: 'none',
        borderRadius: '20px',
        boxShadow: '0 4px 20px rgba(0,0,0,0.15)',
    });

    const TeamMember = styled(Box)(({ theme }) => ({
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
        padding: theme.spacing(2),
        borderRadius: '20px',
        backgroundColor: theme.palette.background.paper,
        border: `1px solid ${purple[400]}`,
        boxShadow: '0 4px 20px rgba(0,0,0,0.1)',
        transition: 'all 0.3s ease',
        '&:hover': {
            transform: 'translateY(-2px)',
            boxShadow: '0 4px 20px rgba(0,0,0,0.2)',
        },
    }));

    const MemberDescription = styled(Box)(({ theme }) => ({
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    }));

    const SocialMediaIcons = styled(Box)(({ theme }) => ({
        display: 'flex',
        justifyContent: 'center',
        marginTop: theme.spacing(4),
        '& > *': {
            margin: theme.spacing(1),
            color: purple[400],
        },
    }));

    const handleLogin = () => {
        navigate('/login');
    };

    const handleRegister = () => {
        navigate('/register');
    };

    return (
        <StyledContainer maxWidth="lg">
            <Banner>
                <Typography variant="h2" component="h1" sx={{
                    background: 'linear-gradient(90deg, #FF00FF, #00FFFF)',
                    WebkitBackgroundClip: 'text',
                    WebkitTextFillColor: 'transparent',
                    fontWeight: 'bold',
                    marginBottom: theme.spacing(2),
                }}>
                    Bienvenue dans le jeu interactif du Pix Card Game !
                </Typography>
                <Typography variant="h5" gutterBottom style={{ opacity: 0.87, marginBottom: theme.spacing(3) }}>
                    Jouez, défiez et amusez-vous!
                </Typography>
                <Typography variant="body1" paragraph style={{ marginBottom: theme.spacing(2) }}>
                    Rejoignez-nous dans une expérience de jeu unique où vous pouvez interagir avec votre streamer préférés et les autres joueurs en utilisant des cartes aux pouvoirs spéciaux. Que vous souhaitiez influencer les décisions en direct, défier d'autres joueurs, ou simplement vous amuser avec des mini-jeux, notre jeu de cartes interactif a quelque chose pour tout le monde. Préparez-vous à une aventure passionnante remplie de stratégies, de surprises, et de beaucoup de plaisir !
                </Typography>
                <Typography variant="body2" color="error" style={{ marginBottom: theme.spacing(3) }}>
                    Version Alpha 1.5
                </Typography>
                <StyledButton variant="contained" onClick={handleRegister}>
                    Commencer à jouer
                </StyledButton>
            </Banner>
            <Grid container spacing={4} justifyContent="center" alignItems="center">
                <Grid item xs={12}>
                    <Card sx={{ backgroundColor: theme.palette.background.paper, color: theme.palette.text.primary, padding: theme.spacing(4), borderRadius: '20px', border: `1px solid ${purple[400]}`, boxShadow: '0 4px 20px rgba(0,0,0,0.15)' }}>
                        <Typography variant="h2" gutterBottom sx={{
                            background: 'linear-gradient(90deg, #FF00FF, #00FFFF)',
                            WebkitBackgroundClip: 'text',
                            WebkitTextFillColor: 'transparent',
                            fontWeight: 'bold',
                            marginBottom: theme.spacing(2),
                            textAlign: 'center',
                        }}>
                            Découvrez le jeu de cartes interactif
                        </Typography>
                        <Typography variant="h6" paragraph textAlign={'center'}>
                            Ce jeu de cartes interactif vous permet de jouer, défier et vous amuser avec vos amis et d'autres joueurs du monde entier. Rejoignez-nous dans cette aventure passionnante et découvrez de nouvelles cartes et stratégies.
                        </Typography>
                        <Video src="https://www.youtube.com/embed/OWvsWdvbCvM" allowFullScreen />
                    </Card>
                </Grid>
            </Grid>
            <Grid container spacing={4} justifyContent="center" alignItems="center" marginTop={4}>
                <Grid item xs={12} md={6}>
                    <TeamMember>
                        <Avatar alt="Betrem" src="/mnt/data/image.png" sx={{ width: 120, height: 120 }} />
                        <Typography variant="h4" gutterBottom>Betrem</Typography>
                        <Typography variant="body2" color="textSecondary">Créateur du jeu & Développeur</Typography>
                        <MemberDescription>
                            <Typography variant="h7" color="textPrimary">
                                Betrem est le créateur de ce jeu, responsable du développement et co-auteur du card game.
                            </Typography>
                        </MemberDescription>
                        <ComputerIcon />
                    </TeamMember>
                </Grid>
                <Grid item xs={12} md={6}>
                    <TeamMember>
                        <Avatar alt="Pixetmotion" src="/mnt/data/image.png" sx={{ width: 120, height: 120 }} />
                        <Typography variant="h4" gutterBottom>Pixetmotion</Typography>
                        <Typography variant="body2" color="textSecondary">Designer & Co-auteur</Typography>
                        <MemberDescription>
                            <Typography variant="h7" color="textPrimary">
                                Pixetmotion est le co-auteur du card game et responsable du design des assets du jeu.
                            </Typography>
                        </MemberDescription>
                        <BrushIcon />
                    </TeamMember>
                </Grid>
            </Grid>
            <Grid container spacing={4} justifyContent="center" alignItems="center" marginTop={4}>
                <Grid item xs={12}>
                    <Typography variant="h4" align="center" gutterBottom>Rejoignez-nous sur nos réseaux sociaux</Typography>
                    <SocialMediaIcons>
                        <IconButton sx={{ color: purple[400] }} component="a" href="https://www.facebook.com">
                            <FacebookIcon />
                        </IconButton>
                        <IconButton sx={{ color: purple[400] }} component="a" href="https://www.tiktok.com">
                            <MusicNoteIcon />
                        </IconButton>
                        <IconButton sx={{ color: purple[400] }} component="a" href="https://www.instagram.com">
                            <InstagramIcon />
                        </IconButton>
                        <IconButton sx={{ color: purple[400] }} component="a" href="https://www.discord.com">
                            <ChatIcon />
                        </IconButton>
                    </SocialMediaIcons>
                </Grid>
            </Grid>
            <Grid container spacing={1} justifyContent="center" alignItems="center" marginTop={4}>
                <Grid item xs={12}>
                    <Typography variant="h4" align="center" gutterBottom>Rejoignez-nous dès maintenant !</Typography>
                    <Typography variant="body1" align="center" paragraph>
                        Connectez-vous ou inscrivez-vous dès maintenant pour commencer à jouer et à défier d'autres joueurs dans le monde entier !
                    </Typography>
                    <Grid container justifyContent="center" spacing={2}>
                        <Grid item>
                            <StyledButton variant="contained" onClick={handleLogin}>Se connecter</StyledButton>
                        </Grid>
                        <Grid item>
                            <StyledButton variant="contained" onClick={handleRegister}>S'inscrire</StyledButton>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </StyledContainer>
    );
}

//root@gotti:~# sudo systemctl restart cardgameapi
//root@gotti:~# sudo systemctl restart apache2


export default Welcome;
